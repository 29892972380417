import React from "react";
import AutoLink from "../AutoLink/AutoLink";

function PostFollow() {
  return (
    <>
      <p className="padding-top">
        ★ Nếu bạn thấy bài viết này hay thì hãy theo dõi mình trên
        Facebook/Youtube để nhận được thông báo khi có bài viết mới nhất nhé:
      </p>
      <ul className="padding-bottom-half">
        <li>
          Facebook Fanpage:{` `}
          <AutoLink to="https://www.facebook.com/hdopensource/">
            Hướng dẫn phần mềm mã nguồn mở
          </AutoLink>
        </li>
        <li>
          Youtube Channel:{` `}
          <AutoLink to="https://www.youtube.com/c/HDOpensource">
            HD Opensource
          </AutoLink>
        </li>
      </ul>
    </>
  );
}

export default PostFollow;
